export default () => {
  return {
    type: 'form',
    url: '/base/farmBackgroundPicture/list',
    data: [
      {
        type: 'actionUpload',
        wrapperCol: 24,
        is_show: true
      }
    ]
  }
}
